import { createMuiTheme, responsiveFontSizes } from '@material-ui/core'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(44, 68,110)',
    },
    secondary: {
      main: 'rgb(165,0,51)',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.Services123PoiTag #Services123': {
          color: 'rgba(0,0,0,.87) !important',
          fontSize: '0.875rem !important',
          fontFamily: 'Roboto,Helvetica,Arial,sans-serif !important',
          fontWeight: '400 !important',
          lineHeight: '1.43 !important',
          letterSpacing: '0.01071em !important',
          '& .Services123_main': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            flexDirection: 'column',
            '& .Services123_search': {
              float: 'none',
              top: 0,
              right: 0,
              margin: 0,
              '& .Services123_search_entry_caption': {
                fontFamily: 'Roboto,Helvetica,Arial,sans-serif !important',
              },
              '& .Services123_search_entry_loc_input': {
                fontFamily: 'Roboto,Helvetica,Arial,sans-serif !important',
                paddingLeft: 6,
                paddingRight: 6,
              },
              '& .Services123_search_button': {
                fontSize: '.875rem !important',
                minWidth: '64px',
                boxSizing: 'border-box',
                transition:
                  'background-color 250ms cubic-bezier(.4, 0, .2, 1) 0s,box-shadow 250ms cubic-bezier(.4, 0, .2, 1) 0s,border 250ms cubic-bezier(.4, 0, .2, 1) 0s',
                fontFamily: 'Roboto,Helvetica,Arial,sans-serif !important',
                fontWeight: 500,
                lineHeight: 1.75,
                borderRadius: 0,
                letterSpacing: '.02857em',
                textTransform: 'uppercase',
                boxShadow:
                  '0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
                padding: '3px 16px',
                backgroundColor: 'rgb(44, 68,110)',
                '&:hover': {
                  boxShadow:
                    '0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%)',
                  backgroundColor: '#1e2f4d',
                },
              },
            },
            '& .Services123_list': {
              float: 'none',
              margin: 0,
              padding: 0,
              width: 'initial',
              flex: '1 1 auto',
              '& .Services123_h1': {
                fontFamily: 'Roboto,Helvetica,Arial,sans-serif !important',
                margin: '0 0 29px !important',
                padding: '16px 0 0 !important',
                '& .Services123_booking': {
                  margin: 0,
                },
              },
              '& .Services123_totalhits': {
                fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
              },
              '& .Services123_poiid': {
                width: '100% !important',
              },
              '& .Services123_poiTitle': {
                fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
              },
            },
            '& .Services123_map': {
              float: 'none',
              width: 'initial',
              margin: 0,
              padding: 0,
              flex: '1 1 auto',
            },
          },
          '&#Services123': {
            '@media (min-width: 768px)': {
              '& .Services123_main': {
                flexDirection: 'row',
                '& .Services123_search': {
                  position: 'absolute',
                },
                '& .Services123_list': {
                  flex: '0 0 300px',
                  paddingRight: 16,
                  '& .Services123_h1': {
                    margin: '0 0 16px !important',
                  },
                },
                '& .Services123_map': {
                  marginTop: 62,
                  paddingLeft: 16,
                },
              },
            },
          },
        },
        '.ad-preloads': {
          position: 'absolute',
          left: -9000,
          top: -9000,
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
      },
    },
    MuiButtonBase: {
      root: {
        borderRadius: 0,
      },
    },
  },
  typography: {
    h1: {
      fontFamily: 'Playfair Display',
      fontWeight: 700,
    },
    h2: {
      fontFamily: 'Playfair Display',
      fontWeight: 700,
    },
    h3: {
      fontFamily: 'Playfair Display',
      fontWeight: 700,
    },
    h4: {
      fontFamily: 'Playfair Display',
      fontWeight: 700,
    },
    h5: {
      fontFamily: 'Playfair Display',
      fontWeight: 700,
    },
    h6: {
      fontFamily: 'Playfair Display',
      fontWeight: 700,
    },
  },
})

export default responsiveFontSizes(theme)
