/* eslint-disable @typescript-eslint/no-var-requires */
const React = require('react')
const { CookiesProvider } = require('react-cookie')
/* eslint-enable @typescript-eslint/no-var-requires */

// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/jsx-filename-extension */
exports.wrapRootElement = ({ element }) => (
  <CookiesProvider>{element}</CookiesProvider>
)
