// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-baedeker-das-original-tsx": () => import("./../../../src/pages/baedeker-das-original.tsx" /* webpackChunkName: "component---src-pages-baedeker-das-original-tsx" */),
  "component---src-pages-baedeker-smart-tsx": () => import("./../../../src/pages/baedeker-smart.tsx" /* webpackChunkName: "component---src-pages-baedeker-smart-tsx" */),
  "component---src-pages-empfehlungen-tsx": () => import("./../../../src/pages/empfehlungen.tsx" /* webpackChunkName: "component---src-pages-empfehlungen-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-premium-tsx": () => import("./../../../src/pages/premium.tsx" /* webpackChunkName: "component---src-pages-premium-tsx" */),
  "component---src-pages-reisefuehrer-tsx": () => import("./../../../src/pages/reisefuehrer.tsx" /* webpackChunkName: "component---src-pages-reisefuehrer-tsx" */),
  "component---src-pages-verlag-tsx": () => import("./../../../src/pages/verlag.tsx" /* webpackChunkName: "component---src-pages-verlag-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

